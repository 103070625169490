@import 'abstracts';
@import 'components/custom-dialog';

@include custom-dialog;

app-inventory-check-form {
  .popup-content {
    min-height: 400px;
  }

  .package-types {
    border-bottom: 1px solid $gray;
    padding-bottom: 10px;
    justify-content: space-between;
    margin-left: 16.67% !important; /* stylelint-disable-line declaration-no-important */
  }

  .inventory-check-section {
    background: $white;
    padding: 16px;
    justify-content: space-between;
  }

  .type {
    color: $text-semi-transparent;
    font-size: 16px;
    font-weight: 600;
  }

  .row > div {
    &:first-child {
      margin: auto;
    }
  }

  .yes {
    color: $danger;
  }

  .no {
    color: $success;
  }
}

.p-datepicker-timeonly {
  width: auto !important; /* stylelint-disable-line declaration-no-important */
}
